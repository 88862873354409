body {
    margin: 0!important;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(78, 79, 81, .1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(78, 79, 81, .6);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4E4F51;
}
